import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "faq", "faqbtn", "faqua", 'faquabtn', "faqen", "faqenbtn", "faqfr", "faqfrbtn", 'uainfopage' , 'uainfopagebtn',"updates", "updatesbtn", "updatesuabtn", "updatesua", "updatesfrbtn", "updatesfr", "updatesen", "updatesenbtn" ]

  connect() {
    console.log('connected')
  }
  faq() {
    this.faqTarget.classList.remove("d-none")
    this.faqbtnTarget.classList.add("active")
    this.uainfopageTarget.classList.add("d-none")
    this.uainfopagebtnTarget.classList.remove("active")
    this.updatesTarget.classList.add("d-none")
    this.updatesbtnTarget.classList.remove("active")
  }

  updates() {
    this.updatesTarget.classList.remove("d-none")
    this.updatesbtnTarget.classList.add("active")
    this.uainfopageTarget.classList.add("d-none")
    this.uainfopagebtnTarget.classList.remove("active")
    this.faqTarget.classList.add("d-none")
    this.faqbtnTarget.classList.remove("active")
  }

  faqua() {
    console.log("Connected ua")
    this.faquaTarget.classList.remove("d-none")
    this.faquabtnTarget.classList.add("active")
    this.faqenTarget.classList.add("d-none")
    this.faqenbtnTarget.classList.remove("active")
    this.faqfrTarget.classList.add("d-none")
    this.faqfrbtnTarget.classList.remove("active")

  }

  faqen() {
    console.log("Connected en")
    this.faqenTarget.classList.remove("d-none")
    this.faqenbtnTarget.classList.add("active")
    this.faquaTarget.classList.add("d-none")
    this.faquabtnTarget.classList.remove("active")
    this.faqfrTarget.classList.add("d-none")
    this.faqfrbtnTarget.classList.remove("active")
  }

  faqfr() {
    console.log("Connected fr")
    this.faqfrTarget.classList.remove("d-none")
    this.faqfrbtnTarget.classList.add("active")
    this.faquaTarget.classList.add("d-none")
    this.faquabtnTarget.classList.remove("active")
    this.faqenTarget.classList.add("d-none")
    this.faqenbtnTarget.classList.remove("active")
  }

  updatesua() {
    console.log("Connected ua")
    this.updatesuaTarget.classList.remove("d-none")
    this.updatesuabtnTarget.classList.add("active")
    this.updatesenTarget.classList.add("d-none")
    this.updatesenbtnTarget.classList.remove("active")
    this.updatesfrTarget.classList.add("d-none")
    this.updatesfrbtnTarget.classList.remove("active")

  }
  updatesen() {
    console.log("Connected ua")
    this.updatesuaTarget.classList.add("d-none")
    this.updatesuabtnTarget.classList.remove("active")
    this.updatesenTarget.classList.remove("d-none")
    this.updatesenbtnTarget.classList.add("active")
    this.updatesfrTarget.classList.add("d-none")
    this.updatesfrbtnTarget.classList.remove("active")

  }
  updatesfr() {
    console.log("Connected ua")
    this.updatesuaTarget.classList.add("d-none")
    this.updatesuabtnTarget.classList.remove("active")
    this.updatesenTarget.classList.add("d-none")
    this.updatesenbtnTarget.classList.remove("active")
    this.updatesfrTarget.classList.remove("d-none")
    this.updatesfrbtnTarget.classList.add("active")

  }


  uainfopage() {
    this.uainfopageTarget.classList.remove("d-none")
    this.uainfopagebtnTarget.classList.add("active")
    this.faqTarget.classList.add("d-none")
    this.faqbtnTarget.classList.remove("active")
    this.updatesTarget.classList.add("d-none")
    this.updatesbtnTarget.classList.remove("active")
  }
  show(event) {

    // console.log(boxdropTarget)

    // console.log(xTarget)
    // console.log(this.buttonTarget)

    this.buttonTarget.classList.add("d-none")
  }

  close() {
    this.boxdropTarget.classList.add("d-none")
    this.buttonTarget.classLIst.remove("d-none")
  }
}
