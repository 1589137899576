import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button", "x", "boxdrop"]

  connect() {
  }
  show(event) {
    console.log("Connected")

    // console.log(boxdropTarget)

    // console.log(xTarget)
    // console.log(this.buttonTarget)

    this.boxdropTarget.classList.remove("d-none")
    this.buttonTarget.classList.add("d-none")
  }

  close() {
    this.boxdropTarget.classList.add("d-none")
    this.buttonTarget.classLIst.remove("d-none")
  }
}
